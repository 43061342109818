import React from 'react';

import "../../theme/BaseTheme.css"

function Disclaimer() {
    return (
        <div>
            <p className={"ui__disclaimer"}>YOU ARE ACCESSING PROTECTED AREA.
                UNAUTHORIZED ACCESS TO THIS SYSTEM IS PROHIBITED!
                You must have explicit permission to access or configure this
                device. All activities performed on this device are logged and
                violations of this policy will be prosecuted under all applicable
                statutes plus all applicable civil rules for damages.</p>
            <br/><br/>
        </div>
    );
}

export default Disclaimer;
