/*
* This software is outdated and posted only for
* informational purposes and fun. There's no
* warranties that this software will ever run on
* your device.
* */
export const oldschool_software = [
    {
        "title": "Jarvis",
        "description": "The first mobile assistant based on the expert system. Able to learn.",
        "icon": "",
        "link": "",
        "key": "9c1b5bc3-6d37-482e-b0b3-9210853dcf23",
    },
    {
        "title": "Jarvis (old)",
        "description": "The first version of Jarvis. It needs activation. Try this license key: ",
        "icon": "",
        "link": "",
        "key": "71b097e3-de0a-47fc-b1d2-4438b1297b12",
    },
]