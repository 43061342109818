import React from 'react';
import {apps_categories} from "../../../data/AppsCategories";
import AppsCategory from "../../widgets/jsx/AppsCategory";
import jwt_decode from "jwt-decode";

function Home() {
    let userData = jwt_decode(window.localStorage.getItem("auth_token"))

    return (
        <div className="apps_container">
            <h1 className={"activity-title"}>Welcome, {userData.username}!</h1>
            {
                apps_categories.map(
                    (x) => <AppsCategory key={x.key} type={x.type} title={x.title}/>
                )
            }
        </div>
    );
}

export default Home;
