import React from 'react';
import "../css/LoadingScreen.css"

function LoadingScreen() {
    return (
        <div className={"ui__loading-screen"}>
            <img src={ "/res/spinner.svg" } alt="" className={"ui__loading-screen-spinner"} />
        </div>
    )
}

export default LoadingScreen;
