import React from 'react';

import "../css/ErrorPage.css";
import {Link} from "react-router-dom";
import {MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function StatusPage({code, title, description, ...props}) {
    return (
        <div className={"error-page"}>
            {code == null ? null : <h1 className={"error-code"}>{code}</h1>}
            {title == null ? null : <h3 className={"error-title"}>{title}</h3>}
            {description == null ? null : <h5 className={"error-description"}>{description}</h5>}

            <br/>
            <div className={"btn-panel"}>
                <Link className={"btn-link"} to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>
        </div>
    );
}

export default StatusPage;
