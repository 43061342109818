import React from 'react'
import "../css/ID5.css"

function ID5(props) {
    return (
        <>
            <p>Redirecting to ID5 CAS...</p>
            <script>
                (function(){window.location.replace("https://id.teslasoft.org/oauth?continue=https://id.teslasoft.org/account/main")})()
            </script>
        </>
    );
}

export default ID5;
