export const apps_categories = [
    {
        type: "account",
        title: "Account",
        key: "b7da5655-e078-4fe2-9a30-7c05796ddf82"
    },
    {
        type: "system",
        title: "System",
        key: "2eb15dc9-55df-42b4-84b6-6e5ebe986062"
    },
    {
        type: "files",
        title: "Project files",
        key: "d296add6-688f-4403-806d-2e9ae858f429"
    },
    {
        type: "servers",
        title: "Infrastructure",
        key: "919752d6-3e3a-4e6a-afab-4c38284549b9"
    },
    {
        type: "apps",
        title: "Manage apps",
        key: "caa46b7c-c4e4-4414-aee2-427dcb259dd9"
    },
]
