import React, {useEffect, useState} from "react";
import axios from "axios";

function ServerListingService(props) {
    const [message, setMessage] = useState(null)
    useEffect(() => {
        axios.get("https://api.digitalocean.com/v2/droplets", {
            headers: {
                Authorization: 'Bearer dop_v1_8747df7717b46b1d5313d6c6c62fc35690676b301ca6ded96a6756ec594d431e'
            }}).then((response) => {
            setMessage(response.data)
        }).catch((error) => {
            setMessage(error.response.data)
        });
    }, [])

    useEffect(() => {
        if (message != null) {
            props.getData(message)
        }
    }, [message, props])

    return (
        <></>
    );
}

export default ServerListingService;
// npx update-browserslist-db@latest