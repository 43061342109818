import React from 'react';
import LoadingScreen from "../../widgets/jsx/LoadingScreen";
import LogoutLoggerService from "../../services/LogoutLoggerService";
import StatusPage from "./StatusPage";

function Logout() {
    return (
        <div>
            <LoadingScreen/>
            <StatusPage title={"Log out"} description={"Please, wait..."}/>
            <LogoutLoggerService username={localStorage.getItem("username")}/>
        </div>
    );
}

export default Logout;
