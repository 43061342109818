import React from 'react';
import "../css/AppWidget.css";
import { Link } from "react-router-dom";
import { AppWidgetTheme } from "../../theme/AppWidgetTheme";
import { ThemeProvider } from "@mui/material/styles";
import { MaterialButtonCard, MaterialButtonErrorCard } from "./MaterialButton";

function AppWidget(props) {
    return (
        <ThemeProvider theme={AppWidgetTheme}>
            <div className="widget__app">
                {
                    props.warn
                        ?
                            <>
                                <Link to={props.link}>
                                    <MaterialButtonErrorCard variant="text" color="error">
                                        <div className="widget__app-link">
                                            <div className="widget__app-icon-warn">
                                                <img className="widget__app-icon-img" src={props.icon} alt=""/>
                                            </div>
                                            <h3 className="widget__app-title-warn">{props.title}</h3>
                                            <p className="widget__app-desc">{props.description}</p>
                                        </div>
                                    </MaterialButtonErrorCard>
                                </Link>
                            </>
                        :
                            <>
                                <Link to={props.link}>
                                    <MaterialButtonCard variant="text">
                                        <div className="widget__app-link">
                                            <div className="widget__app-icon">
                                                <img className="widget__app-icon-img" src={props.icon} alt=""/>
                                            </div>
                                            <h3 className="widget__app-title">{props.title}</h3>
                                            <p className="widget__app-desc">{props.description}</p>
                                        </div>
                                    </MaterialButtonCard>
                                </Link>
                            </>
                }
            </div>
        </ThemeProvider>
    )
}

export default AppWidget;
