import React from 'react';
import "../css/TeslasoftID.css"

function TeslasoftID() {

    return (
        <>
            <a className={"btn-id5"} href = "https://id.teslasoft.org/oauth?continue=https://id.teslasoft.org/protected/ID5AuthCallback">
                <div className={"btn-bg"}>
                    <img alt={"id5"} src="https://id.teslasoft.org/nfcid/icon.png" className={"icon-x"}/>
                    <span className={"text-x"}>&nbsp;&nbsp;&nbsp;Sign In with CAS&nbsp;&nbsp;&nbsp;</span>
                </div>
            </a>
        </>
    );
}

export default TeslasoftID;
