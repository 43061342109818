import React from 'react'
import "../css/IVisit.css"

function IVisit(props) {
    return (
        <>
            <p>Redirecting to a new UI...</p>
            <script>
                (function(){window.location.replace("https://travel.teslasoft.org/admin/")})()
            </script>
        </>
    );
}

export default IVisit;
