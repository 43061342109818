import React, {useEffect} from 'react';
import LoadingScreen from "../../widgets/jsx/LoadingScreen";
import { useNavigate } from "react-router-dom";

function ID5AuthCallback() {
    const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")

    useEffect(() => {
        localStorage.setItem("auth_token", token);
        navigate("/");
    }, [navigate, token])

    return (
        <>
            <LoadingScreen/>
        </>
    );
}

export default ID5AuthCallback;
