import { styled } from '@mui/material/styles';
import '../../theme/colors.css'
import {TextField} from "@mui/material";

export const MaterialEditText = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: 'var(--color-primary-accent)',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'var(--color-primary-accent)',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'var(--color-primary-accent)',
        },
    },
}));