import React from 'react'
import "../css/AccountCard.css"
import {Link} from "react-router-dom";

function AccountCard(props) {
    return (
        <>
            <Link className={"account_card_link"} to={"/accountInfo/" + props.user_id}>
                <div className={"account_card_bg"}>
                    <img className={"account_card_icon"} src = {"https://id.teslasoft.org/protected/users/" + props.user_icon} alt={"Account icon"}/>
                    <div className={"account_card_text_info"}>
                        <p className={"account_legal_name"}>{props.first_name} {props.last_name}</p>
                        <p className={"account_user_name"}>({props.user_name})</p>
                        <p className={"account_user_id"}>{props.user_id}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </div>
                </div>
            </Link>
            &nbsp;&nbsp;&nbsp;
        </>
    );
}

export default AccountCard;