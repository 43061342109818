import React from 'react'
import "../css/Github.css"
import {Link} from "react-router-dom";
import {MaterialButton, MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function _Github(props) {
    return (
        <>
            <p>You need to have permissions to view Github repo. If you have all necessary permissions, click "Github repo" button.</p>
            <br/>
            <div className={"btn-panel"}>
                <a className={"btn-link"} href="https://github.com/TeslasoftLLC">
                    <MaterialButton className={"button"} variant="contained" ><p className={"btn-content btn-mtrl"}>Github repo</p></MaterialButton>
                </a>
                <a className={"btn-link"} href="mailto:admin@teslasoft.org">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Request Github access</p></MaterialButtonOutlined>
                </a>
                <Link className={"btn-link"} to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>
            <br/>
        </>
    );
}

export default _Github;
