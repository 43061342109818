import React from 'react';
import "../css/Proxy.css"
import {Link} from "react-router-dom";
import {MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function _Proxy(props) {
    return (
        <>
            <h1 className={"activity-title"}>Add user to proxy</h1>
            <p className={"text-selectable"}>sudo htpasswd /etc/squid/htpasswd USERNAME</p>
            <div className={"button-frame"}>
                <Link to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>
        </>
    );
}

export default _Proxy;