import React from 'react';

function Domains() {
    return (
        <div className="apps_container">
            <h1 className={"activity-title"}>Domains</h1>

        </div>
    );
}

export default Domains;
