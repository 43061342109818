import React from 'react'
import "../css/Downloads.css"
import {Link} from "react-router-dom";
import AppWidget from "../../widgets/jsx/AppWidget";
import {software_list} from "../../../data/Software";
import {oldschool_software} from "../../../data/OldSchoolSoftware";
import {MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function Downloads(props) {
    return (
        <div className={"apps_container"}>
            <h1 className={"activity-title"}>Download software</h1>
            <br/>
            <p className={"text-default"}>
                Here you can find software for internal use.&nbsp;
                <span className={"text-warn"}>Distribution of this software outside the organization is strictly prohibited and prosecuted by law!</span>
            </p>

            <br/>
            <div className={"apps-panel"}>
                {software_list.map(
                    (x) => <AppWidget key={x.key} title={x.title} description={x.description} icon={x.icon} link={x.link}/>
                )}
            </div>
            <hr className="divider"/>
            <p className={"text-default"}>The software below is deprecated morally and may or may not run on your device. An archived copy of packages are published for informational purposes and fun.</p>
            <hr className="divider"/>
            <div className={"apps-panel"}>
                {oldschool_software.map(
                    (x) => <AppWidget key={x.key} title={x.title} description={x.description} icon={x.icon} link={x.link}/>
                )}
            </div>
            <br/>
            <div className={"btn-panel"}>
                <Link className={"btn-link"} to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>
            <br/>
        </div>
    );
}

export default Downloads;
