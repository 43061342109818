import React from 'react';
import "../css/EmbeddedLoadingScreen.css"

function EmbeddedLoadingScreen(props) {
    return (
        <div className={"ui__loading-screen_embedded"}>
            <img src={ "/res/spinner.svg" } alt="" className={"ui__loading-screen-spinner_embedded"} />
        </div>
    )
}

export default EmbeddedLoadingScreen;
