export const software_list = [
    {
        "title": "Teslasoft Core",
        "description": "Provide Teslasoft services and experience on mobile devices.",
        "icon": "",
        "link": "",
        "key": "43bb767f-a594-49bb-a26b-bd3db6fb072b",
    },
    {
        "title": "Teslasoft SmartCard",
        "description": "Allows you to authenticate using the SmartCard.",
        "icon": "",
        "link": "",
        "key": "a380b1e5-f654-4d76-9b66-a02483a92d6f",
    },
    {
        "title": "Teslasoft ID sandbox",
        "description": "Just a sandbox. Just for devs.",
        "icon": "",
        "link": "",
        "key": "dfa540f8-4448-4352-8983-9d94bddef047",
    },
    {
        "title": "SpeakGPT",
        "description": "AI-powered mobile assistant.",
        "icon": "",
        "link": "",
        "key": "fd9ed103-6b03-4afa-9d4f-28abb86219b5",
    },
    {
        "title": "AES 256 Tools",
        "description": "Encrypt/decrypt messages with AES256.",
        "icon": "",
        "link": "",
        "key": "85aefca0-d303-4e6b-85aa-0b27577be0c0",
    },
    {
        "title": "iVisit",
        "description": "App for travelling.",
        "icon": "",
        "link": "",
        "key": "f13fa9c2-5045-4030-ae89-535950f3fec5",
    },
    {
        "title": "RGB Controller",
        "description": "IoT devices controller.",
        "icon": "",
        "link": "",
        "key": "ab6c9efe-bced-40ea-b9e1-c18e340f1e31",
    },
    {
        "title": "CoreX libraries",
        "description": "Native libraries for Teslasoft apps.",
        "icon": "",
        "link": "",
        "key": "93bd7939-ef82-4e7c-9fb4-a6de0dd759b8",
    },
    {
        "title": "CoreX DevServer",
        "description": "Remote console for id.teslasoft.org.",
        "icon": "",
        "link": "",
        "key": "62e82aa8-bb64-41e6-afd4-309f88cc184c",
    },
    {
        "title": "Teslasoft Network",
        "description": "Mobile version of this panel.",
        "icon": "",
        "link": "",
        "key": "ee87fbd6-a7e3-4ff4-9523-a1769e2a1b3d",
    },
    {
        "title": "VX Crypto",
        "description": "Cryptographic library to encrypt organizational internal files.",
        "icon": "",
        "link": "",
        "key": "32c13400-2ea7-4f5e-8a87-42ba4e7c8f42",
    }
]