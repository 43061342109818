import React from 'react';
import classes from "../css/NavBar.module.css";
import logo from "../../../res/app-icon.svg"
import { Link } from "react-router-dom";

function NavBar() {
    return (
        <div className={classes.navbar}>
            <Link to="/">
                <img src={logo} className={classes.navbar__icon} alt="logo"/>
            </Link>

            <Link className={classes.navbar__link} to="/">
                <h1 className={classes.navbar__title}>Teslasoft Network - Project management system</h1>
            </Link>
        </div>
    );
}

export default NavBar;

