import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

export const MaterialAlertDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        color: "var(--color-accent-900)",
        backgroundColor: "var(--color-accent-0)",
        textTransform: "none",
        borderRadius: "24px",
        backgroundImage: "none"
    },
}));