import React, {useEffect} from "react";
import axios from "axios";

function LogoutLoggerService(props) {
    useEffect(() => {
        axios.get("https://id.teslasoft.org/protected/LogoutApi.php?username=" + props.username).then((response) => {
            localStorage.clear()
            window.location.replace("/");
        }).catch((error) => {
            localStorage.clear()
            window.location.replace("/");
        });
    }, [props.username])

    return (
        <></>
    );
}

export default LogoutLoggerService;
