import React, { useEffect, useState } from 'react';

import SecurityService from "../services/SecurityService";
import StatusPage from "../pages/jsx/StatusPage";
import LoadingScreen from "../widgets/jsx/LoadingScreen";

import "../theme/BaseTheme.css"
import logo from "../../res/logo.svg";
import Disclaimer from "../widgets/jsx/Disclaimer";
import Auth from "../pages/jsx/Auth";

function BaseDialogUi({children, hasTitle, title}) {

    const [security, setSecurity] = useState("")
    const [loading, setLoading] = useState(true)
    const [primaryAuthState, setPrimaryAuthState] = useState(false)

    useEffect(() => {
        if (security !== undefined) {
            try {
                if (security.code === 200) {
                    setLoading(false)
                    // console.log("Security: OK")
                    setPrimaryAuthState(false)
                } else if (security.code === 401) {
                    setLoading(false)
                    // console.log("Security: UNAUTHORIZED")
                } else if (security.code === 403) {
                    setLoading(false)
                    // console.log("Security: NO_PROXY")
                } else if (security.code === 500) {
                    setLoading(false)
                    // console.log("Security: PROXY_UNTRUSTED")
                }
            } catch (e) {
                console.log(e.toString())
            }
        }
    }, [security])

    return (
        <div className={"ui"}>
            {localStorage.getItem("auth_token") == null ?
                <>
                    <img src={logo} className={"app_logo"} alt={""} />
                    <div className={"ui__dialog"}>
                        <h1 className={"ui__dialog__title"}>AUTHENTICATION REQUIRED</h1>
                        <Auth sauth={setPrimaryAuthState}/>
                    </div>
                    <Disclaimer />
                </>
                :
                <>
                    <SecurityService setSecurityState={setSecurity} token={localStorage.getItem("auth_token")}/>
                    {primaryAuthState === true ? <SecurityService setSecurityState={setSecurity} token={localStorage.getItem("auth_token")}/> : null}
                    {loading ?
                        <LoadingScreen />
                        :
                        <>
                            {
                                security.code === 401
                                    ?
                                    <>
                                        <img src={logo} className={"app_logo"} alt={""} />
                                        <div className={"ui__dialog"}>
                                            <h1 className={"ui__dialog__title"}>AUTHENTICATION REQUIRED</h1>
                                            <Auth sauth={setPrimaryAuthState}/>
                                        </div>
                                        <Disclaimer />
                                    </>
                                    :
                                    <>
                                        {
                                            security.code === 403
                                                ?
                                                <>
                                                    {/*<img src={logo} className={"app_logo"} alt={""} />*/}
                                                    <div className={"ui__dialog"}>
                                                        <StatusPage
                                                            title={"Access denied"}
                                                            description={"Your connection has been blocked by Security Manager because of security rules. You need to use Teslasoft proxy (proxy.teslasoft.org) to access this resource. Required access level: 4"} />
                                                    </div>
                                                    <Disclaimer />
                                                </>
                                                :
                                                <>
                                                    {
                                                        security.code === 500
                                                            ?
                                                            <>
                                                                {/*<img src={logo} className={"app_logo"} alt={""} />*/}
                                                                <div className={"ui__dialog"}>
                                                                    <StatusPage
                                                                        title={"Access denied"}
                                                                        description={"Your connection has been blocked by Security Manager because we do not trust your proxy server. It may be hacked. Security keys exchanging failed. Also this error may occur because proxy server is overloaded. Please wait a minute and try again."} />
                                                                </div>
                                                                <Disclaimer />
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    security.code === 200
                                                                        ?
                                                                        <>
                                                                            {/*<img src={logo} className={"app_logo"} alt={""} />*/}
                                                                            <div className={"ui__dialog"}>
                                                                                {children}
                                                                            </div>
                                                                            <Disclaimer />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {/*<img src={logo} className={"app_logo"} alt={""} />*/}
                                                                            <div className={"ui__dialog"}>
                                                                                <StatusPage
                                                                                    title={"Server error"}
                                                                                    description={"Something went wrong while performing security check"} />
                                                                            </div>
                                                                            <Disclaimer />
                                                                        </>
                                                                }
                                                            </>
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </>
                    }
                </>
            }
        </div>
    );
}

export default BaseDialogUi;
