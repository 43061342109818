import React from 'react';
import {Route, Routes} from "react-router-dom";
import BaseDialogUi from "../window/BaseDialogUI";
import Home from "../pages/jsx/Home";
import StatusPage from "../pages/jsx/StatusPage";
import Logout from "../pages/jsx/Logout"
import Account from "../pages/jsx/Account";
import IVisit from "../pages/jsx/IVisit";
import ID5 from "../pages/jsx/ID5";
import _Proxy from "../pages/jsx/Proxy";
import About from "../pages/jsx/About";
import Wiki from "../pages/jsx/Wiki";
import _Github from "../pages/jsx/Github";
import Downloads from "../pages/jsx/Downloads";
import AccountsList from "../pages/jsx/AccountsList";
import AccountInfo from "../pages/jsx/AccountInfo";
import ID5AuthCallback from "../pages/jsx/ID5AuthCallback";
import LogsViewer from "../pages/jsx/LogsViewer";
import Servers from "../pages/jsx/Servers";
import Domains from "../pages/jsx/Domains";

function BaseRoutes() {
    return (
        <div>
            <Routes>
                <Route path="/" element={
                    <BaseDialogUi>
                        <Home/>
                    </BaseDialogUi>
                } exact />
                <Route path="/accountInfo/:id" element={
                    <BaseDialogUi>
                        <AccountInfo />
                    </BaseDialogUi>
                }/>
                <Route path="/ID5AuthCallback" element={
                    <ID5AuthCallback/>
                }/>
                <Route path="/account" element={
                    <BaseDialogUi>
                        <Account/>
                    </BaseDialogUi>
                } exact />
                <Route path="/idaccount" element={
                    <BaseDialogUi>
                        <ID5/>
                    </BaseDialogUi>
                } exact />
                <Route path="/servers" element={
                    <BaseDialogUi>
                        <Servers/>
                    </BaseDialogUi>
                } exact />
                <Route path="/id" element={
                    <BaseDialogUi>

                    </BaseDialogUi>
                } exact />
                <Route path="/accounts" element={
                    <BaseDialogUi>
                        <AccountsList/>
                    </BaseDialogUi>
                } exact />
                <Route path="/logs" element={
                    <BaseDialogUi>
                        <LogsViewer/>
                    </BaseDialogUi>
                } exact />
                <Route path="/repos" element={
                    <BaseDialogUi>
                        { /* eslint-disable-next-line */ }
                        <_Github/>
                    </BaseDialogUi>
                } exact />
                <Route path="/documents" element={
                    <BaseDialogUi>

                    </BaseDialogUi>
                } exact />
                <Route path="/explorer" element={
                    <BaseDialogUi>

                    </BaseDialogUi>
                } exact />
                <Route path="/domains" element={
                    <BaseDialogUi>
                        <Domains/>
                    </BaseDialogUi>
                } exact />
                <Route path="/ivisit" element={
                    <BaseDialogUi>
                        <IVisit/>
                    </BaseDialogUi>
                } exact />
                <Route path="/proxy" element={
                    <BaseDialogUi>
                        { /* eslint-disable-next-line */ }
                        <_Proxy/>
                    </BaseDialogUi>
                } exact />
                <Route path="/software" element={
                    <BaseDialogUi>
                        <Downloads/>
                    </BaseDialogUi>
                } exact />
                <Route path="/wiki" element={
                    <BaseDialogUi>
                        <Wiki/>
                    </BaseDialogUi>
                } exact />
                <Route path="/about" element={
                    <BaseDialogUi>
                        <About/>
                    </BaseDialogUi>
                } exact />
                <Route path="/logout" element={
                    <BaseDialogUi>
                        <Logout/>
                    </BaseDialogUi>
                } exact />
                <Route path="*" element={
                    <BaseDialogUi>
                        <StatusPage code={"404"} title={"Not found"} description={"This page was not found"}/>
                    </BaseDialogUi>
                } exact />
            </Routes>
        </div>
    );
}

export default BaseRoutes;
