import React, {useEffect, useState} from 'react';
import "../css/AccountsList.css"
import {Link} from "react-router-dom";
import GetAccounts from "../../services/GetAccounts";
import AccountCard from "../../widgets/jsx/AccountCard";
import EmbeddedLoadingScreen from "../../widgets/jsx/EmbeddedLoadingScreen";
import {MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function AccountsList(props) {

    const [accounts, getAccounts] = useState([])
    const [accountLoaded, getAccountLoaded] = useState(false)

    useEffect(() => {
        if (Array.isArray(accounts)) {
            if (accounts != null && accounts.length > 0) {
                getAccountLoaded(true)
            }
        } else {
            console.error("Server respond with an invalid response. Possibly you are not authenticated or your session has expired.")
            localStorage.clear()
            window.location.reload()
        }
    }, [accounts])

    return (
        <>
            <h1 className={"activity-title"}>Accounts</h1>

            <div className={"button-frame"}>
                <Link to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>

            <GetAccounts fetchAccounts={getAccounts}/>

            { accountLoaded === false ? <EmbeddedLoadingScreen /> : null }

            <br/>
            <div className={"account_cards"}>
                { accountLoaded ?
                    accounts.map(
                        (x) => <AccountCard key={x.user_id} first_name={x.first_name} last_name={x.last_name}
                                            user_name={x.user_name} user_id={x.user_id} user_icon={x.user_icon}/>
                    ) : null
                }
            </div>

            <div className={"button-frame"}>
                <Link to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>
        </>
    );
}

export default AccountsList;