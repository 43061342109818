import React, {useState, useEffect} from 'react';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import "../../widgets/css/Input.css"
import "../../widgets/css/Button.css"
import classes from "../css/Auth.module.css";
import LoadingScreen from "../../widgets/jsx/LoadingScreen";
import AuthService from "../../services/AuthService";
import {MaterialButton, MaterialButtonOutlined, MaterialButtonTonal} from "../../widgets/jsx/MaterialButton";
import {MaterialEditText} from "../../widgets/jsx/MaterialEditText";
import TeslasoftID from "../../widgets/jsx/TeslasoftID";
import {MaterialAlertDialog} from "../../widgets/jsx/AlertDialog";

function Auth(props) {
    const [password, setPassword] = useState({
        password: '',
        showPassword: false,
        helperText: '',
        errorPassword: true,
    })

    const [username, setUsername] = useState({
        username: '',
        helperText: '',
        errorUsername: true,
    })

    const [auth, setAuth] = useState(false)
    const [open, setOpen] = useState(false)
    const [dialog, setDialog] = useState({
        title: "",
        message: ""
    })
    const [credentials, setCredentials] = useState("")

    useEffect(() => {
        if (credentials !== undefined) {
            try {
                if (!username.errorUsername && !password.errorPassword) {
                    if (credentials.code === 200) {
                        localStorage.setItem("auth_token", credentials.message)
                        props.sauth(true)
                    } else {
                        if (credentials.message !== undefined) {
                            setDialog({
                                title: "Authentication failed",
                                message: credentials.message
                            })
                        }
                        localStorage.clear()
                        setAuth(false)
                        setOpen(true)
                    }
                }
            } catch (e) {
                console.log(e.toString())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials])

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setPassword({
                password: event.target.value,
                ...password.showPassword,
                errorPassword: true,
                helperText: "Please enter password"
            })
        } else {
            setPassword({
                password: event.target.value,
                ...password.showPassword,
                errorPassword: false,
                helperText: ""
            })
        }
    }

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setUsername({
                username: event.target.value,
                errorUsername: true,
                helperText: "Please enter username"
            })
        } else {
            setUsername({
                username: event.target.value,
                errorUsername: false,
                helperText: ""
            })
        }
    }

    const handleClickShowPassword = () => {
        setPassword({
            ...password.password,
            showPassword: !password.showPassword,
            ...password.errorPassword,
            ...password.helperText
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const sendForm = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (username.errorUsername || password.errorPassword) {
            setDialog({
                title: "Error",
                message: "Please fill all blanks"
            })
            setOpen(true)
        } else {
            setAuth(true)
        }
    };

    return (
        <>
            {auth ? <LoadingScreen /> : null}
            {auth ? <AuthService username={username.username} password={password.password} setCredentialsState={setCredentials}/> : null}
            <MaterialAlertDialog
                open={open}
                onClose={() => {setOpen(false)}}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">

                <DialogTitle>{dialog.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{dialog.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MaterialButtonTonal onClick={() => {setOpen(false)}}>Close</MaterialButtonTonal>
                </DialogActions>
            </MaterialAlertDialog>

            <form className={classes.auth_form}>
                <div className={"input-frame"}>
                    <MaterialEditText
                        className={"input"}
                        id="username"
                        label="Username"
                        variant="outlined"
                        value={username.username}
                        helperText={username.helperText}
                        onChange={ handleUsernameChange } />
                </div>
                <div className={"input-frame"}>
                    <MaterialEditText
                        className={"input"}
                        id="password"
                        label="Password"
                        type={ password.showPassword ? 'text' : 'password' }
                        value={ password.password }
                        helperText={password.helperText}
                        onChange={ handlePasswordChange }
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={ handleClickShowPassword }
                                        onMouseDown={ handleMouseDownPassword }
                                        edge="end" >

                                        { password.showPassword ? <VisibilityOff /> : <Visibility /> }
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </div>
                <div className={classes.action_buttons}>
                    <div className={"button-frame"}>
                        <a href="mailto:admin@teslasoft.org">
                            <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Request access</p></MaterialButtonOutlined>
                        </a>
                    </div>
                    <div className={"button-frame"}>
                        <MaterialButton className={"button"} variant="contained" onClick={ sendForm } type={"submit"} ><p className={"btn-content btn-mtrl"}>Login</p></MaterialButton>
                    </div>
                </div>
                <div>
                    <br/>
                    <TeslasoftID/>
                    <br/>
                </div>
            </form>
        </>
    );
}

export default Auth;
