import React, {useState} from 'react'
import "../css/ServerView.css"

function ServerView(props) {
    const [expanded, setExpanded] = useState(false)

    const toggle = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <div className={"server-card"}>
                <div className={"non-expandable"} onClick={ toggle }>
                    <img className={"server-icon"} src={"/res/ic_sv.svg"} alt={"server"}/>
                    <div className={"server-info-non-expandable"}>
                        <p className={"server-property"}><span className={"value"}>{ props.name }</span></p>
                        <p className={"server-property"}>IP: <span className={"value"}>{ props.ip }</span></p>
                    </div>
                    <div className={ props.status === "active" ? "tooltip circle-green" : "tooltip circle-red" }>
                        <div className={"tooltip-bg"}>
                            <p className={"tooltiptext"}>{ props.status }</p>
                        </div>
                    </div>
                </div>
                <div className={"expandable"} style={ expanded ? { display: "block" } : { display: "none" } }>
                    <br/>
                    <hr className={"hr"}/>
                    <br/>
                    <div className={"server-additional-info"}>
                        <table className={"server-info-table"}>
                            <tbody>
                                <tr>
                                    <td className={"server-table-col"}>
                                        <p className={"server-property"}>RAM: <span className={"value"}>{ props.ram }</span></p>
                                    </td>
                                    <td className={"server-table-col"}>
                                        <p className={"server-property"}>Disk: <span className={"value"}>{ props.disk }</span></p>
                                    </td>
                                    <td className={"server-table-col"}>
                                        <p className={"server-property"}>System: <span className={"value"}>{ props.system }</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"server-table-col"}>
                                        <p className={"server-property"}>Region: <span className={"value"}>{ props.region }</span></p>
                                    </td>
                                    <td className={"server-table-col"}>
                                        <p className={"server-property"}>Price: <span className={"value"}>{ props.price }</span></p>
                                    </td>
                                    <td className={"server-table-col"}>
                                        <p className={"server-property"}>Type: <span className={"value"}>{ props.type }</span></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServerView;