import React from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BaseTheme } from "./components/theme/Theme";

import NavBar from "./components/widgets/jsx/NavBar";
import BaseRoutes from "./components/routes/BaseRoutes";

import './App.css';

function App() {
    const baseTheme = createTheme(BaseTheme);

    return (
        <ThemeProvider theme={baseTheme}>
            <Router className="App">
                <NavBar/>
                <BaseRoutes/>
            </Router>
        </ThemeProvider>
    );
}

export default App;
