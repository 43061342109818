export const AppWidgetTheme = {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    width: 260,
                    height: 260,
                },
            },
        }
    },
}
