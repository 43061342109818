import React from 'react';
import classes from "../css/Auth.module.css";
import "../css/Account.css"
import {Link} from "react-router-dom";
import {MaterialEditText} from "../../widgets/jsx/MaterialEditText";
import {MaterialButton, MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function Account(props) {
    return (
        <>
            <h1 className={"activity-title"}>Account</h1>
            <form className={classes.auth_form}>
                <div className={"name-group"}>
                    <div className={"f"}>
                        <MaterialEditText
                            className={"name-field input"}
                            id="first-name"
                            label="First name"
                            variant="outlined"/>
                    </div>
                    <div className={"f"}>
                        <MaterialEditText
                            className={"name-field input"}
                            id="last-name"
                            label="Last name"
                            variant="outlined"/>
                    </div>
                </div>
                <div className={"input-frame"}>
                    <MaterialEditText
                        className={"input"}
                        id="email"
                        label="Email"
                        variant="outlined"/>
                </div>
                <div className={"input-frame"}>
                    <MaterialEditText
                        className={"input"}
                        id="pass"
                        label="Security pass #"
                        variant="outlined"/>
                </div>
                <div className={"input-frame"}>
                    <MaterialEditText
                        className={"input"}
                        id="passwd"
                        label="Confirm password"
                        type="password"
                        variant="outlined"/>
                </div>
                <div className={classes.action_buttons}>
                    <div className={"button-frame"}>
                        <Link to="/">
                            <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Cancel</p></MaterialButtonOutlined>
                        </Link>
                    </div>
                    <div className={"button-frame"}>
                        <MaterialButton className={"button"} variant="contained"  type={"submit"} ><p className={"btn-content btn-mtrl"}>Save</p></MaterialButton>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Account;
