import React from 'react'
import "../css/Wiki.css"
import {Link} from "react-router-dom";
import {MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function Wiki(props) {
    return (
        <>
            { /* eslint-disable-next-line */ }
            <p>// TODO: Add a redirect to external site.</p>
            { /* eslint-disable-next-line */ }
            <p>// TODO: Wiki should be located on a different package, not on this admin UI.</p>
            { /* eslint-disable-next-line */ }
            <p>// TODO: This admin panel have already contains a lot of stuff. Putting wiki here might overload project and create confusions for devs.</p>
            <Link to="/">
                <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
            </Link>
        </>
    );
}

export default Wiki;
