import React, {useEffect, useState} from 'react';
import AppWidget from "./AppWidget";
import "../../pages/css/Home.css"
import {apps} from "../../../data/Apps"

function AppsCategory(props) {
    const [toggle, setToggle] = useState(localStorage.getItem("menu_" + props.type))
    const [toggleIcon, setToggleIcon] = useState("/res/ic_collapse.svg")

    useEffect(() => {
        if (toggle === null) setToggle(true)
        if (toggle === "false") {
            setToggleIcon("/res/ic_expand.svg")
        } else {
            setToggleIcon("/res/ic_collapse.svg")
        }

        localStorage.setItem("menu_" + props.type, toggle)
    }, [props.type, toggle])

    return (
        <>
            <div className={"widget-container"}>
                <div className="toggle" onClick={
                    () => {
                        if (toggle === "false") setToggle("true")
                        else setToggle("false")
                    }
                }>
                    <h1 className="func_type">{props.title}</h1>
                    <img className="toggle__icon" src={toggleIcon} alt=""/>
                </div>

                {
                    toggle !== "false"
                    ?
                        <div className={"widgets"}>
                            {
                                apps.map(
                                    (x) => x.type === props.type ? <AppWidget key={x.key} link={x.link} icon={x.icon} title={x.title} description={x.description} warn={x.warn}/> : null
                                )
                            }
                        </div>
                    :
                        null
                }
            </div>
        </>
    );
}

export default AppsCategory;
