import React from 'react'
import "../css/AccountInfo.css"
import {Link, useParams} from "react-router-dom";
import {MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function AccountInfo(props) {

    let { id } = useParams();

    return (
        <>
            <h1 className={"activity-title"}>Account info</h1>
            <br/>
            <p>{id}</p>
            <br/>
            <div className={"btn-panel"}>
                <Link className={"btn-link"} to="/accounts">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to account list</p></MaterialButtonOutlined>
                </Link>
                <Link className={"btn-link"} to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>
            <br/>
        </>
    );
}

export default AccountInfo;
