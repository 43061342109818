export const apps = [
    {
        link: "/account",
        icon: "/res/ic_account.svg",
        title: "My account",
        description: "Edit info about me, change password.",
        warn: false,
        key: "eb8244f6-e133-42bd-9d8e-d880342a7367",
        type: "account",
    },
    {
        link: "/idaccount",
        icon: "/res/ic_id.svg",
        title: "My ID5 account",
        description: "View and edit my ID5 info.",
        warn: false,
        key: "4f882b80-1c2d-4d87-8005-b85a0c0ba4ba",
        type: "account",
    },
    {
        link: "/id",
        icon: "/res/ic_id.svg",
        title: "ID manager",
        description: "Manage Teslasoft ID accounts, set permissions.",
        warn: false,
        key: "825dd15e-6b35-42b1-b511-72ea772509ad",
        type: "apps",
    },
    {
        link: "/accounts",
        icon: "/res/ic_users.svg",
        title: "Account manager",
        description: "Manage accounts in this system.",
        warn: false,
        key: "eb5c81fc-2e54-410a-a9c6-239b5bc8b2b8",
        type: "account",
    },
    {
        link: "/logs",
        icon: "/res/ic_logs.svg",
        title: "System logs",
        description: "View system logs.",
        warn: false,
        key: "5eebb060-b78f-4d6a-bc2b-eb0d6202aba0",
        type: "system",
    },
    {
        link: "repos",
        icon: "/res/ic_git.svg",
        title: "Repositories",
        description: "View and manage project repositories.",
        warn: false,
        key: "5a649979-4a9d-4788-92da-2ab31be68b1a",
        type: "files",
    },
    {
        link: "/documents",
        icon: "/res/ic_documents.svg",
        title: "Project documents",
        description: "Access project documents.",
        warn: false,
        key: "e7c47523-d8eb-45be-95de-8399e3fee9ee",
        type: "files",
    },
    {
        link: "/explorer",
        icon: "/res/ic_explorer.svg",
        title: "File explorer",
        description: "Explore server files.",
        warn: false,
        key: "ba9732af-4492-48b6-993d-2006a57d9991",
        type: "files",
    },
    {
        link: "/servers",
        icon: "/res/ic_servers.svg",
        title: "Manage servers",
        description: "View, manage servers and virtual machines, view network topology.",
        warn: false,
        key: "277f77c9-1c83-438b-ad88-11fe6ae774ee",
        type: "servers",
    },
    {
        link: "/domains",
        icon: "/res/ic_globe.svg",
        title: "Manage domains",
        description: "View and manage domains.",
        warn: false,
        key: "277f77c8-1c83-438b-ad88-11fe6ae774ee",
        type: "servers",
    },
    {
        link: "/ivisit",
        icon: "/res/ic_ivisit.svg",
        title: "iVisit",
        description: "Manage database of iVisit app.",
        warn: false,
        key: "4362c3f5-ad02-4607-a29b-b23a437e6fe7",
        type: "apps",
    },
    {
        link: "/proxy",
        icon: "/res/ic_proxy.svg",
        title: "Proxy",
        description: "Add users to teslasoft Proxy.",
        warn: false,
        key: "dfbc2267-0c17-4831-a61f-6a76629f4727",
        type: "account",
    },
    {
        link: "/software",
        icon: "/res/ic_download.svg",
        title: "Download software",
        description: "Download private software to manage systems.",
        warn: false,
        key: "76250eb8-fd8c-43d5-b7ae-3abb1ee9494f",
        type: "apps",
    },
    {
        link: "/wiki",
        icon: "/res/ic_wiki.svg",
        title: "Wiki",
        description: "Read wiki and access products documentations.",
        warn: false,
        key: "dec241ca-d512-45d6-b38d-ad1aa54aa89f",
        type: "system",
    },
    {
        link: "/about",
        icon: "/res/ic_about.svg",
        title: "About system",
        description: "View info about this system.",
        warn: false,
        key: "5690663e-d284-42db-af45-7f0c5e955188",
        type: "system",
    },
    {
        link: "/logout",
        icon: "/res/ic_logout.png",
        title: "Logout",
        description: "Log out from this system.",
        warn: true,
        key: "494e7158-e1f1-4ed5-923d-fb68b018bc07",
        type: "account",
    }
]
