import React, {useEffect, useState} from "react";
import axios from "axios";

function LogsProviderService(props) {
    const [message, setMessage] = useState(null)
    useEffect(() => {
        axios.get("https://id.teslasoft.org/protected/Logs?auth_token=" + window.localStorage.auth_token).then((response) => {
            setMessage(response.data)
        }).catch((error) => {
            setMessage(error.response.data)
        });
    }, [props.token])

    useEffect(() => {
        if (message != null) {
            props.getLogs(message)
        }
    }, [message, props])

    return (
        <></>
    );
}

export default LogsProviderService;
// npx update-browserslist-db@latest