import React, {useEffect, useState} from 'react'
import "../css/LogsViewer.css"
import {Link} from "react-router-dom";
import {MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";
import LogsProviderService from "../../services/LogsProviderService";
import EmbeddedLoadingScreen from "../../widgets/jsx/EmbeddedLoadingScreen";
import Prism from "prismjs";

function LogsViewer(props) {

    const [logs, setLogs] = useState(undefined)
    const [data, getData] = useState(undefined)
    const [logsLoaded, setLogsLoaded] = useState(undefined)
    const [errorState, setErrorState] = useState("")

    useEffect(() => {
        if (data !== undefined) {
            setLogsLoaded(true)
            setLogs(data.message)

            if (data.code !== 200) {
                setErrorState(data.message)
            }
        }
    }, [data]);

    useEffect(() => {
        Prism.highlightAll();
    });

    return (
        <>
            <h1 className={"activity-title"}>Logs</h1>
            <br/>
            <a href={"#b"}>
                <div className={"fab"}>
                    <p className={"fab-text"}>Scroll to bottom</p>
                </div>
            </a>
            <div>
                {
                    logsLoaded ?
                        <>
                            { errorState !== "" ? <>
                                <div className={"log-container-error"}>
                                    <pre>
                                        <code className={"language-error"} children={ "ERROR: " + errorState } />
                                    </pre>
                                </div>
                                <br/>
                            </> :
                                <>
                                    <div className={"log-container"}>
                                        <pre>
                                            <code children={logs} className={`language-log`} />
                                            <code id = "b">&nbsp;</code>
                                        </pre>
                                    </div>
                                    <br/>
                                </>
                                 }
                        </>
                        : <EmbeddedLoadingScreen/>
                }
            </div>
            <div className={"btn-panel"}>
                <Link className={"btn-link"} to="/">
                    <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
                </Link>
            </div>

            <LogsProviderService getLogs = { getData } />
        </>
    );
}

export default LogsViewer;
