import React, {useEffect, useState} from "react";
import axios from "axios";

function SecurityService(props) {
    const [message, setMessage] = useState(null)
    useEffect(() => {
        axios.get("https://id.teslasoft.org/protected/AuthenticatorApi.php?username=" + props.username + "&password=" + props.password).then((response) => {
            setMessage(response.data)
        }).catch((error) => {
            setMessage(error.response.data)
        });
    }, [props.password, props.username])

    useEffect(() => {
        if (message != null) {
            props.setCredentialsState(message)
        }
    }, [message, props])

    return (
        <></>
    );
}

export default SecurityService;
