import React from 'react'
import "../css/About.css"
import {Link} from "react-router-dom";
import {MaterialButtonOutlined} from "../../widgets/jsx/MaterialButton";

function About(props) {
    return (
        <>
            <h1 className={"activity-title"}>About system</h1>
            <p>Name: Project Management System</p>
            <p>Developer: Teslasoft</p>
            <p>Version: 0.7.0</p>
            <br/>
            <Link to="/">
                <MaterialButtonOutlined className={"button"} variant="outlined" ><p className={"btn-content-outlined btn-mtrl"}>Back to home</p></MaterialButtonOutlined>
            </Link>
        </>
    );
}

export default About;
